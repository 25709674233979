import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';

import { createHeaderSlice } from 'app/slices/header.slice';
import { createRadioSlice } from 'app/slices/radio.slice';
import { createVacanciesSlice } from 'app/slices/vacancies.slice';
import { createCulturalCodeModalSlice } from 'app/slices/culturalCodeModal.slice';

const _useBoundStore = create(
  immer((set, get) => ({
    ...createHeaderSlice(set),
    ...createVacanciesSlice(set),
    ...createRadioSlice(set, get),
    ...createCulturalCodeModalSlice(set),
  })),
);

export function useBoundStore(selector) {
  if (!selector) {
    selector = () => undefined;
  }
  return _useBoundStore(useShallow(selector));
}
