import { scroller } from 'react-scroll';

export function goTo(element, settings = null) {
  scroller.scrollTo(element, {
    duration: 600,
    smooth: true,
    offset: -110,
    ...settings,
  });
}
