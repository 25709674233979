import zen from 'assets/Icons/zen.svg';
import rutube from 'assets/Icons/rutube.svg';
import youtube from 'assets/Icons/youtube.svg';

export const joinUsLinks = [
  {
    img: zen,
    company: 'Яндекс Дзен',
    link: 'https://dzen.ru/kulturniykod',
    imageProps: { w: { mobile: '68px', laptop: '111px' }, h: { mobile: '67px', laptop: '109px' } },
  },
  {
    img: rutube,
    company: 'RuTube',
    link: 'https://rutube.ru/channel/44655132/',
    imageProps: { w: { mobile: '53px', laptop: '86px' }, h: { mobile: '48px', laptop: '78px' } },
  },
  {
    img: youtube,
    company: 'YouTube',
    link: 'http://www.youtube.com/@%D0%9A%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%BD%D1%8B%D0%B9_%D0%9A%D0%BE%D0%B4/featured',
    imageProps: { w: { mobile: '48px', laptop: '78px' }, h: { mobile: '48px', laptop: '78px' } },
  },
];
