import React, { memo } from 'react';
import { motion } from 'framer-motion';
import { Box, VStack } from '@chakra-ui/react';

import { headerSelector } from 'app/selectors';
import { useBoundStore } from 'app/store';

const MenuToggle = ({ isCulturalCode = false, ...props }) => {
  const { isLight, toggleMenuOpen } = useBoundStore(headerSelector);
  const variants = [
    {
      variants: {
        closed: { d: 'M 2 2.5 L 20 2.5' },
        open: { d: 'M 3 16.5 L 17 2.5' },
      },
    },
    {
      d: 'M 2 9.423 L 20 9.423',
      variants: {
        closed: { opacity: 1 },
        open: { opacity: 0 },
      },
      transition: { duration: 0.1 },
    },
    {
      variants: {
        closed: { d: 'M 2 16.346 L 20 16.346' },
        open: { d: 'M 3 2.5 L 17 16.346' },
      },
    },
  ];

  return (
    <VStack
      justify="space-between"
      cursor="pointer"
      onClick={toggleMenuOpen}
      h="32px"
      w="32px"
      zIndex={1100}
      mt={{ mobile: '10px', tablet: '0px' }}
      {...props}
    >
      <Box
        as={motion.svg}
        width="100%"
        height="100%"
        viewBox="0 0 23 23"
        objectFit="cover"
        stroke={`text.${isCulturalCode ? 'white' : isLight ? 'white' : 'black'}`}
      >
        {variants?.map((val, i) => (
          <Path key={i} {...val} />
        ))}
      </Box>
    </VStack>
  );
};

const Path = props => (
  <Box as={motion.path} fill="transparent" strokeWidth="2" strokeLinecap="round" {...props} />
);

export default memo(MenuToggle);
