import {
  Box,
  Center,
  Flex,
  Img,
  Stack,
  VStack,
  Text,
  Grid,
  Accordion,
  Link,
} from '@chakra-ui/react';
import React, { forwardRef, memo } from 'react';

import Wrapper from 'shared/Wrapper';
import OfficeInfo from 'shared/OfficeInfo';
import AccordionInfo from 'shared/AccordionInfo';
import PopupBlockedSocials from 'shared/PopupBlockedSocials';

import commonAssets from 'assets/common';
import downAngleWhite from 'assets/Icons/DownAngleWhite.svg';
import illustration from 'assets/CulturalCode/footerIllustration.svg';
import { culturalCodeSocialMedias, offices } from 'constants/footer';

const CulturalCodeFooter = (props, ref) => {
  return (
    <VStack
      ref={ref}
      id="footer"
      w="full"
      bgColor="culturalCode.bg"
      boxSizing="border-box"
      pt={{ mobile: '62px', tablet: '92px', laptop: '100px' }}
      px={{ laptop: '52px', tablet: '27px', mobile: '24px' }}
      color="bg.grayLight"
      spacing={{ mobile: '40px', laptop: '100px' }}
      pos="relative"
      zIndex="3"
      {...props}
    >
      <Img
        src={illustration}
        alt="Иллюстрация"
        maxW={{ mobile: 'unset', xxxl: 'full' }}
        h={{ mobile: '180px', tablet: '240px', mg: '280px', xxxl: '450px', xd: '440px' }}
        objectFit="cover"
        pos="absolute"
        top={{ mobile: '-160px', tablet: '-210px', mg: '-250px', xxxl: '-355px', xd: '-360px' }}
        left={{ mobile: '-520px', tablet: '-605px', mg: '-650px', xxxl: '700px', xd: '815px' }}
      />
      <Wrapper
        gap={{ laptop: '100px', mobile: '40px' }}
        mt="0"
        pt="0"
        pb="0"
        px={{ laptop: '48px', tablet: '25px', mobile: '0px' }}
      >
        <Stack
          justify="space-between"
          spacing={{ mobile: '32px', tablet: '15px' }}
          direction={{ tablet: 'row', mobile: 'column' }}
          w="full"
        >
          <Text as="h2" color="text.white">
            Наши офисы
          </Text>
          <VStack
            justify="end"
            align="start"
            spacing={{ laptop: '24px', tablet: '12px', mobile: '16px' }}
          >
            <Flex gap={{ laptop: '16px', mobile: '8px' }} whiteSpace="nowrap" alignItems="center">
              <Img
                src={commonAssets.icons.Phone.black}
                alt="Phone"
                color="text.white"
                alignItems="center"
                filter="brightness(0) invert(1)"
                boxSize={{ laptop: '24px', tablet: '20px', mobile: '24px' }}
              />
              <Center
                textStyle="h1"
                fontWeight={500}
                fontSize={{ desktop: '24px', laptop: '20px', tablet: '16px', mobile: '12px' }}
              >
                +7 (495) 138 39 39
              </Center>
            </Flex>
            <Flex
              gap={{ laptop: '16px', mobile: '8px' }}
              whiteSpace="nowrap"
              fill="text.white"
              alignItems="center"
            >
              <Img
                src={commonAssets.icons.Mail.black}
                alt="Mail"
                color="text.white"
                alignItems="center"
                filter="brightness(0) invert(1)"
                boxSize={{ laptop: '24px', tablet: '20px', mobile: '24px' }}
              />
              <Center
                textStyle="h1"
                fontWeight={500}
                fontSize={{ desktop: '24px', laptop: '20px', tablet: '16px', mobile: '12px' }}
              >
                info@astondevs.ru
              </Center>
            </Flex>
            <Flex
              gap={{ mobile: '18px', laptop: '32px' }}
              flexWrap="nowrap"
              w="full"
              pos="relative"
            >
              {culturalCodeSocialMedias.map(({ src, href, alt, isBlocked = false }, i) =>
                isBlocked ? (
                  <PopupBlockedSocials key={i}>
                    <Link href={href} target="_blank">
                      <Img
                        src={src}
                        alt={alt}
                        boxSize={{
                          mobile: '24px',
                          tablet: '28px',
                          laptop: '32px',
                          desktop: '40px',
                        }}
                      />
                    </Link>
                  </PopupBlockedSocials>
                ) : (
                  <Link key={i} href={href} target="_blank">
                    <Img
                      src={src}
                      alt={alt}
                      boxSize={{ mobile: '24px', tablet: '28px', laptop: '32px', desktop: '40px' }}
                    />
                  </Link>
                ),
              )}
            </Flex>
          </VStack>
        </Stack>
        <VStack spacing={{ laptop: '56px', mobile: '24px' }} w="full" align="start">
          <Grid
            hideBelow="sm"
            justifyContent="space-between"
            columnGap={{ laptop: '101px', mobile: '32px' }}
            w="full"
            templateColumns={{
              laptop: 'repeat(3, minmax(auto, 346px))',
              sm: 'repeat(3, minmax(auto, 187px))',
              mobile: 'repeat(1, auto)',
            }}
            rowGap={{ laptop: '48px', sm: '24px' }}
          >
            {offices?.map(val => (
              <OfficeInfo key={val.city} {...val} dotStyles={{ bgColor: 'culturalCode.accent' }} />
            ))}
          </Grid>
          <Accordion
            allowToggle
            flex={1}
            w="full"
            gap="21px"
            display="flex"
            flexDirection="column"
            hideFrom="sm"
          >
            {offices?.map(({ city, address }, i) => (
              <AccordionInfo
                key={i}
                title={city}
                content={address}
                panelStyle={{ textStyle: 'h2', color: '#A7A9A5', ps: '20px', pt: '12px' }}
                leftIcon={
                  <Box
                    borderRadius="full"
                    placeSelf="center"
                    boxSize="8px"
                    bgColor="culturalCode.accent"
                  />
                }
                rightIcon={downAngleWhite}
                titleSx={{ fontWeight: 500 }}
                contentSx={{ fontWeight: 500 }}
              />
            ))}
          </Accordion>
        </VStack>
      </Wrapper>
      <Box w="full" maxW={{ laptop: '1390px' }}>
        <Img src={commonAssets.logo.Aston.light} alt="Logo" w="full" />
      </Box>
    </VStack>
  );
};

export default memo(forwardRef(CulturalCodeFooter));
