import React, { memo } from 'react';
import { Text } from '@chakra-ui/react';

const LozungCapsText = ({ children, ...props }) => (
  <Text
    as="p"
    color="culturalCode.accent"
    fontFamily="Lozung Caps, sans-serif"
    fontWeight="400"
    lineHeight="100%"
    letterSpacing="-0.04em"
    {...props}
  >
    {children}
  </Text>
);

export default memo(LozungCapsText);
